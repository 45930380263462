/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'User',
        title: 'jobs',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/userjobs'
    },
    {
        id   : 'AdminSettings',
        title: 'Setting',
        type : 'basic',
        icon : 'mat_outline:settings',
        link : 'admin-profile'
    },
    {
        id   : 'clients',
        title: 'Clients',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/clients'
    },
    // {
    //     id   : 'ViewClientJobs',
    //     title: 'ViewClientJobs',
    //     type : 'basic',
    //     icon : 'heroicons_outline:document-text',
    //     link : '/view-client-jobs'
    // },
   
    // {
    //     id   : 'invoice',
    //     title: 'Invoices',
    //     type : 'basic',
    //     icon : 'heroicons_outline:document-text',
    //     link : '/invoice'
    // },
    {
        id   : 'accountmanagment',
        title: 'Account Managment',
        type : 'basic',
        icon : 'heroicons_outline:document-text',
        link : '/accountmanagment'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        icon : 'mat_outline:settings',
        link : '/settings'
    },
    {
        id   : 'jobs',
        title: 'Jobs',
        type : 'basic',
        icon : 'heroicons_outline:briefcase',
        link : '/jobs'
    },
    // {
    //     id   : 'ViewUserJobs',
    //     title: 'ViewUserJobs',
    //     type : 'basic',
    //     icon : 'heroicons_outline:collection',
    //     link : '/view-user-jobs'
    // },
    // {
    //     id      : 'Settings',
    //     title   : 'Settings',
    //     type    : 'collapsable',
    //     icon    : 'mat_outline:settings',
    //     link    : '/apps/settings',
    //     children: [
    //         {
    //             id        : 'bs-schedule',
    //             title     : 'BS Schedule',
    //             type      : 'basic',
    //             link      : '/bs-schedule',
    //             exactMatch: true
    //         },
    //         {
    //             id   : 'je-crawler',
    //             title: 'JE Crawler',
    //             type : 'basic',
    //             link : '/je-crawler'
    //         },
    //         // {
    //         //     id   : 'reconciliation',
    //         //     title: 'Reconciliation',
    //         //     type : 'basic',
    //         //     link : '/reconciliation'
    //         // },
    //     ]
    // },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
