import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                // this.user = user;

                //temporary code for user sidebar
                if (localStorage.getItem('User') == 'Admin') {
                    this.user = {
                        id: "cfaad35d-07a3-4447-a6c3-d8c3d54fd5df",
                        name: localStorage.getItem('name'),
                        email: localStorage.getItem('email'),
                        avatar: localStorage.getItem('image'),
                        status: "online"
                    }
                }
                else if(localStorage.getItem('User') == 'User'){
                    this.user = {
                        id: "cfaad35d-07a3-4447-a6c3-d8c3d54fd5dfd",
                        name: localStorage.getItem('name'),
                        email: localStorage.getItem('email'),
                        avatar: "assets/images/avatars/brian-hughes.jpg",
                        status: "online"
                    }
                }
                else {
                    this.user = {
                        id: "cfaad35d-07a3-4447-a6c3-d8c3d54fd5da",
                        name: localStorage.getItem('name'),
                        email: localStorage.getItem('email'),
                        avatar: localStorage.getItem('image'),
                        status: "online"
                    }
                }
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        localStorage.removeItem('User');
        this._router.navigate(['/sign-out']);
    }

    ProfileRed(): void {
        const User = localStorage.getItem('User');
        if (User == 'Admin') {
            this._router.navigate(['/admin-profile']);
        }
        else {
            this._router.navigate(['/profile']);
        }
    }
}
