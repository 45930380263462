import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { identifierName } from '@angular/compiler';
@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userUrl = `${environment.api_url3}user/users`; 
    private _userAddUrl = `${environment.api_url3}user/adduser`; 
    private _getUserUrl = `${environment.api_url3}user/getuser`; // Adjust with your API endpoint
    private _updateUserUrl = `${environment.api_url3}user/updateuser`; 
    private _deleteUserUrl = `${environment.api_url3}user/deleteuser`; 
    private _SaveTokenUrl = `${environment.api_url3}user/savetoken`; 
    private _saveCompanyDetail = `${environment.api_url3}company/updatecompany`; 
    private _getCompanyDetails = `${environment.api_url3}company/getcompanybyid`; 
    private _imageUpload = `${environment.api_url3}company/imageupload`; 
    private _createJob = `${environment.api_url3}company/createjob`; 
    private _createJobDocument = `${environment.api_url3}company/createjobdocument`; 
    private _getJobs = `${environment.api_url3}company/getjobs`; 
    private _getJobById = `${environment.api_url3}company/getjobbyid`; 
    private _updateJobUrl = `${environment.api_url3}company/updatejob`; 
    private _deleteJobDocumentUrl = `${environment.api_url3}company/deletejobdocument`; 
    private _deleteJobUrl = `${environment.api_url3}company/deletejobs`; 
    private _getUserByCompanyId = `${environment.api_url3}company/getUserByCompanyId`; 

    private _clientUrl = `${environment.api_url3}user/getclientbyid`; 
    private _addClientUrl = `${environment.api_url3}user/addclients`; 
    private _getClientUrl = `${environment.api_url3}user/getclients`; // Adjust with your API endpoint
    private _updateClientUrl = `${environment.api_url3}user/updateclient`; 
    private _deleteClientUrl = `${environment.api_url3}user/deleteclient`; 
    private _updateClientStatusUrl = `${environment.api_url3}user/updateclientstatus`; 

    private _updateUserStatusUrl = `${environment.api_url3}user/updateuserstatus`; 


    private _processDocumentToggleUrl = `${environment.api_url3}company/processdocumenttoggle`; 
    private _getJobByUserId = `${environment.api_url3}user/getjobbyuserid`; 
    private _getClientJobsById = `${environment.api_url3}user/getClientJobsById`; 
    private _getUserJobsById = `${environment.api_url3}user/getUserJobsById`; 


    private _sendCustomerKeyUrl = `${environment.api_url3}user/sendCustomerKey`; 
    private _downloadJsonFile = `${environment.api_url3}user/downloadJsonFile`; 

    private _viewUserJobUrl = `${environment.api_url3}company/viewUserJobs`; 
    private _viewClientJobUrl = `${environment.api_url3}company/viewClientJobs`; 

    private _forgotPassword = `${environment.api_url3}user/forgotPassword`; 


    private _addJobsJson = `${environment.api_url3}company/addJobsJson`; 
    private _getJsonData = `${environment.api_url3}company/getJsonData`; 
    private _sendDNAConvertedData = `${environment.api_url3}company/sendDNAConvertedData`; 
    private _fetchJsonData = `${environment.api_url3}user/fetchJsonData`; 
    private _deleteJsonData = `${environment.api_url3}user/deleteJsonData`; 






    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
    getUsers(companyId: any): Observable<User[]> {
      console.log(companyId); // Ensure companyId is not undefined here
      return this._httpClient.get<User[]>(`${this._userUrl}/${companyId}`);
    }

      addUser(user: any): Observable<any> {
        return this._httpClient.post<any>(`${this._userAddUrl}`, user);
      }
      getUserById(userId: number): Observable<User> {
        return this._httpClient.get<User>(`${this._getUserUrl}/${userId}`);
      }
      updateUser(updatedUser: any): Observable<any> {
        return this._httpClient.put<any>(`${this._updateUserUrl}/${updatedUser.id}`, updatedUser);
      }
      deleteUser(userId: number): Observable<any> {
        return this._httpClient.delete(`${this._deleteUserUrl}/${userId}`);
    }

    saveToken(tokenData: any): Observable<any> {
        return this._httpClient.post<any>(`${this._SaveTokenUrl}`, tokenData);
      }
      saveCompanyDetail(updatedUser: any): Observable<any> {
        return this._httpClient.put<any>(`${this._saveCompanyDetail}/${updatedUser.id}`, updatedUser);
      }
      getCompanyDetails(id: string): Observable<User> {
        return this._httpClient.get<User>(`${this._getCompanyDetails}/${id}`);
      }

      uploadImage(formData: FormData): Observable<any> {
       // const uploadUrl = `${this.apiUrl}/upload-image`; // Replace with your image upload endpoint
        return this._httpClient.post<any>(`${this._imageUpload}`, formData);
      }

      createJob(formData: FormData): Observable<any> {
        // const uploadUrl = `${this.apiUrl}/upload-image`; // Replace with your image upload endpoint
         return this._httpClient.post<any[]>(`${this._createJob}`, formData);
       }
      
       createJobDocument(jobDocumentData: any): Observable<any> {
        // const uploadUrl = `${this.apiUrl}/upload-image`; // Replace with your image upload endpoint
         return this._httpClient.post<any>(`${this._createJobDocument}`, jobDocumentData);
       }

      //  getJobs(companyId:any): Observable<any[]> {
      //   return this._httpClient.get<any[]>(`${this._getJobs}/${companyId}`);
      // }
      getJobs(companyId: string, userId?: string): Observable<any[]> {
        let url = `${this._getJobs}/${companyId}`;
    
        // If userId is provided, append it as a query parameter
        if (userId) {
            url += `?userId=${userId}`;
        }
    
        return this._httpClient.get<any[]>(url);
    }
    
      getJobById(jobId: number): Observable<User> {
        return this._httpClient.get<User>(`${this._getJobById}/${jobId}`);
      }
      updateJob(formData: FormData): Observable<any> {
        const jobId = formData.get('jobId'); // Get jobId from formData
        console.log('FormData:', formData); 
        if (!jobId) {
          throw new Error('No jobId found in formData.');
        }
        return this._httpClient.put<any>(`${this._updateJobUrl}/${jobId}`, formData);
      }
      deleteJobs(userId: number): Observable<any> {
        return this._httpClient.delete(`${this._deleteJobUrl}/${userId}`);
    }
    deleteJobDocument(userId: number): Observable<any> {
        return this._httpClient.delete(`${this._deleteJobDocumentUrl}/${userId}`);
    }

    getUserByCompanyId(companyId: number): Observable<User[]> {
      return this._httpClient.get<User[]>(`${this._getUserByCompanyId}/${companyId}`);
    }

    getClients(): Observable<any[]> {
      return this._httpClient.get<any[]>(`${this._getClientUrl}`);
    }

    addClients(user: any): Observable<any> {
      return this._httpClient.post<any>(`${this._addClientUrl}`, user);
    }

    getClientById(clientId: number): Observable<User> {
      return this._httpClient.get<User>(`${this._clientUrl}/${clientId}`);
    }
    updateClient(updatedUser: any): Observable<any> {
      return this._httpClient.put<any>(`${this._updateClientUrl}/${updatedUser.id}`, updatedUser);
    }

    deleteClient(CompanyId: number): Observable<any> {
      return this._httpClient.delete(`${this._deleteClientUrl}/${CompanyId}`);
  }


  updateClientStatus(clientId: string, status: string): Observable<any> {
    return this._httpClient.put(`${this._updateClientStatusUrl}/${clientId}`, { status });
  }

  updateUserStatus(userId: string, status: string): Observable<any> {
    return this._httpClient.put(`${this._updateUserStatusUrl}/${userId}`, { status });
  }

  processDocumentToggle(documentId: any, status: string ,userId:any): Observable<any> {
    // console.log(companyId); // Ensure companyId is not undefined here
    return this._httpClient.put(`${this._processDocumentToggleUrl}/${documentId}`,{status,userId});
  }

  getJobByUserId(userId: any): Observable<User> {
    return this._httpClient.get<User>(`${this._getJobByUserId}/${userId}`);
  }

  
  getUserJobsById(userId: any): Observable<User> {
    return this._httpClient.get<User>(`${this._getUserJobsById}`);
  }

  getClientJobsById(userId: any): Observable<User> {
    return this._httpClient.get<User>(`${this._getClientJobsById}`);
  }
  // sendCustomerKey(customerKey: any): Observable<any> {
  //   return this._httpClient.put(`${this._sendCustomerKeyUrl}`);
  // }
  sendCustomerKey(customerKey: any,companyId:any): Observable<any> {
    // console.log(companyId); // Ensure companyId is not undefined here
    return this._httpClient.post(`${this._sendCustomerKeyUrl}/${companyId}`,{customerKey:customerKey});
  }
  downloadJsonFile(documentId: any): Observable<Blob> {
    return this._httpClient.post(`${this._downloadJsonFile}/${documentId}`, {}, { responseType: 'blob' });
  }
  viewUserJobs(userId: any): Observable<User> {
    return this._httpClient.get<User>(`${this._viewUserJobUrl}/${userId}`);
  }

  viewClientJobs(userId: any): Observable<User> {
    return this._httpClient.get<User>(`${this._viewClientJobUrl}/${userId}`);
  }

  forgotPassword(user: any): Observable<any> {
    return this._httpClient.post<any>(`${this._forgotPassword}`, user);
  }

  addJobsJson(user: any): Observable<any> {
    return this._httpClient.post<any>(`${this._addJobsJson}/${user.documentId}`, user);
  }

  getJsonData(data: any): Observable<User> {
    return this._httpClient.get<User>(`${this._getJsonData}/${data}`);
  }

  sendDNAConvertedData(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._sendDNAConvertedData}`,data);
  }

  fetchJsonData(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._fetchJsonData}/${data.documentId}`,data);
  }
  deleteJsonData(JobJsonId: number): Observable<any> {
    return this._httpClient.delete(`${this._deleteJsonData}/${JobJsonId}`);
}
}
 