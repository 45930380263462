import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},

    // Auth routes for guests
    {
        path: '',
        // canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'set-password', loadChildren: () => import('app/modules/auth/set-password/set-password.module').then(m => m.SetPasswordModule)}
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },
    

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
        ]
    },

    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
        ]
    },

    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'admin-profile', loadChildren: () => import('app/modules/admin/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'clients', loadChildren: () => import('app/modules/admin/clients/clients.module').then(m => m.ClientsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'add-client', loadChildren: () => import('app/modules/admin/add-client/add-client.module').then(m => m.AddClientModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'add-jobs-json/:id', loadChildren: () => import('app/modules/admin/add-jobs-json/add-jobs-json.module').then(m => m.AddJobsJsonModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'edit-client/:id', loadChildren: () => import('app/modules/admin/edit-client/edit-client.module').then(m => m.EditClientModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'view-client/:id', loadChildren: () => import('app/modules/admin/view-client/view-client.module').then(m => m.ViewClientModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'profile', loadChildren: () => import('app/modules/client/profile/profile.module').then(m => m.ProfileModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'invoice', loadChildren: () => import('app/modules/client/invoice/invoice.module').then(m => m.InvoiceModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'users', loadChildren: () => import('app/modules/client/users/users.module').then(m => m.UsersModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'add-user', loadChildren: () => import('app/modules/client/add-user/add-user.module').then(m => m.AddUserModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'edit-user/:id', loadChildren: () => import('app/modules/client/edit-user/edit-user.module').then(m => m.EditUserModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'view-user/:id', loadChildren: () => import('app/modules/client/view-user/view-user.module').then(m => m.ViewUserModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'view-jobs/:id', loadChildren: () => import('app/modules/admin/view-jobs/view-jobs.module').then(m => m.ViewJobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'edit-jobs/:id', loadChildren: () => import('app/modules/admin/edit-jobs/edit-jobs.module').then(m => m.EditJobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'edit-job-document/:id', loadChildren: () => import('app/modules/admin/edit-job-document/edit-job-document.module').then(m => m.EditJobDocumentModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'settings', loadChildren: () => import('app/modules/client/settings/settings.module').then(m => m.SettingsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'bs-schedule', loadChildren: () => import('app/modules/Settings/bs-schedule/bs-schedule.module').then(m => m.BsScheduleModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'je-crawler', loadChildren: () => import('app/modules/Settings/je-crawler/je-crawler.module').then(m => m.JeCrawlerModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'reconciliation', loadChildren: () => import('app/modules/Settings/reconciliation/reconciliation.module').then(m => m.ReconciliationModule)},
        ]
    },

    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'accountmanagment', loadChildren: () => import('app/modules/admin/accountmanagment/accountmanagment.module').then(m => m.AccountmanagmentModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'jobs', loadChildren: () => import('app/modules/admin/jobs/jobs.module').then(m => m.JobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'createjobs', loadChildren: () => import('app/modules/admin/createjobs/createjobs.module').then(m => m.CreatejobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'userjobs', loadChildren: () => import('app/modules/user/user-jobs/user-jobs.module').then(m => m.UserJobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'view-client-jobs', loadChildren: () => import('app/modules/admin/view-client-jobs/view-client-jobs.module').then(m => m.ViewClientJobsModule)},
        ]
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'view-user-jobs', loadChildren: () => import('app/modules/client/view-user-jobs/view-user-jobs.module').then(m => m.ViewUserJobsModule)},
        ]
    },
    // {
    //     path: '',
    //     canMatch: [AuthGuard],
    //     component: LayoutComponent,
    //     resolve: {
    //         initialData: InitialDataResolver,
    //     },
    //     children: [
    //         {path: 'add-jobs-json', loadChildren: () => import('app/modules/admin/add-jobs-json/add-jobs-json.nodule').then(m => m.AddJobsJsonComponent)},
    //     ]
    // },
    // {
    //     path: '',
    //     canMatch: [AuthGuard],
    //     component: LayoutComponent,
    //     resolve: {
    //         initialData: InitialDataResolver,
    //     },
    //     children: [
    //         {path: 'AdminSettings', loadChildren: () => import('app/modules/admin/view-client-jobs/view-client-jobs.module').then(m => m.ViewClientJobsModule)},
    //     ]
    // },
];
